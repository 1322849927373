import { CSSProperties, forwardRef, useEffect, useRef } from "react";
import * as echarts from "echarts";
import { EChartsOption } from "echarts";

interface Props {
  option: EChartsOption;
  style?: CSSProperties
}

const Chat = (props: Props, ref: any) => {
  const { option, style } = props;
  const chartContainerRef = useRef(null);

  const chartInstance = useRef<any>(null);

  const defaultStyle: CSSProperties = {
    width: 600,
    height: 300,
    // backgroundColor: "#fff",
  };

  useEffect(() => {
    drawChat(option);
    return () => {};
  }, [option]);

  const drawChat = (option: EChartsOption) => {
    // 初始化echarts实例
    chartInstance.current = echarts.init(chartContainerRef.current);
    // 设置echarts图表的配置项
    chartInstance.current.setOption(option);
  };
  return <div style={style || defaultStyle} ref={chartContainerRef}></div>;
};

export default forwardRef(Chat);
