import { EChartsOption } from "echarts";
import Chat from "./Chat";


const EquipmentShotDownChart = () => {

    const option: EChartsOption = {
        tooltip: {
            trigger: 'item'
        },
        legend: {
            textStyle: {
                color: 'rgba(255,255,255,0.72)', // 图例文字颜色
            },
            // 将图例移动到右侧
            orient: 'vertical', // 图例垂直排列
            top: 'center', // 图例在垂直方向上居中
            left: '60%' // 图例距离容器右侧的距离
        },
        series: [
            {
                name: '设备停机原因',
                type: 'pie',
                radius: ['50%', '90%'],
                avoidLabelOverlap: false,
                padAngle: 5,
                itemStyle: {
                    borderRadius: 10
                },

                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: 40,
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
                center: ['40%', '46%'],
                data: [
                    { value: 1048, name: '维修保养' },
                    { value: 735, name: '更换物料' },
                    { value: 580, name: '工艺调整' },
                    { value: 484, name: '人工停机' },
                ]
            }
        ]
    };

    return (
        <Chat option={option} />
    )
}

export default EquipmentShotDownChart;