import React, { PropsWithChildren, useEffect, useRef } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { OrbitControls, Environment, useGLTF } from '@react-three/drei';
//@ts-ignore
import venice_sunset_1k from './local-hdri/venice_sunset_1k.hdr';

interface Props {
    modelPath: string
}

const Model = (props: Props) => {
    const { modelPath } = props;
    const { scene } = useGLTF(modelPath);
    const modelRef = useRef<any>(null);
    useFrame(() => {
        if (modelRef.current) {
            modelRef.current.rotation.y += 0.005; // 每帧旋转0.01弧度，可以根据需要调整旋转速度
        }
    });
    return <primitive ref={modelRef} object={scene} />;
};

const ModelViewer = (props: Props) => {
    // function GlassFloor() {
    //     const floorRef = useRef<any>();

    //     // 可选：使地板随时间轻微移动或添加其他动态效果
    //     useFrame(() => {
    //         floorRef.current.rotation.z += 0.001; // 示例动态效果
    //     });

    //     return (
    //         <mesh ref={floorRef} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} position={[0, -1, 0]}>
    //             <planeGeometry args={[10, 10]} />
    //             <meshPhysicalMaterial
    //                 color={'#ffffff'} // 玻璃基色，可以根据需要调整
    //                 metalness={0.5} // 金属感，玻璃通常有较低的金属感，但这会影响反射效果
    //                 roughness={0.1} // 粗糙度，较低的值会增加反射
    //                 //@ts-ignore
    //                 transparency={0.5} // 透明度，调整以达到所需的透明效果
    //                 transparent={true} // 必须设置为true以启用透明度效果
    //                 reflectivity={1} // 反射率，较高的值模拟光滑表面的强反射
    //                 refractionRatio={0.98} // 折射率，玻璃的折射率通常接近1，但可以根据需要调整以改变效果
    //             />
    //         </mesh>
    //     );
    // }

    // const RotatingMesh = ({ children }: PropsWithChildren) => {
    //     // 使用ref来引用mesh，这样我们可以在useFrame钩子中访问并修改它
    //     const meshRef = useRef<any>();

    //     useFrame((state, delta) => {
    //         // 检查meshRef.current是否存在
    //         if (meshRef.current) {
    //             // 每帧旋转模型
    //             // delta是距离上一帧的时间差，用于确保动画的平滑性
    //             // 你可以调整旋转速度，这里使用的是0.5 * delta
    //             meshRef.current.rotation.x += 0.5 * delta;
    //             meshRef.current.rotation.y += 0.5 * delta;
    //         }
    //     });

    //     return <mesh ref={meshRef}>{children}</mesh>;
    // };

    const CameraController = () => {
        const { camera } = useThree();
        const controls = useRef();

        // const modelRef = useRef();

        useEffect(() => {
            camera.zoom = 42; // 调整这个值以放大或缩小
            // camera.rotation.x = Math.PI / 10; 
            camera.updateProjectionMatrix();
        }, [camera]);

        // useFrame((state, delta) => {
        //     camera.rotation.y += 0.5 * delta;
        // })
        //@ts-ignore
        return <OrbitControls ref={controls} enablePan={true} enableZoom={true} enableRotate={true} />;
    }

    const { modelPath } = props;

    // function RotatingModel(props: Props) {
    //     const modelRef = useRef<any>(null);
    //     useFrame(({ clock }) => {
    //         const elapsedTime = clock.getElapsedTime();
    //         // 每秒旋转30度，可以根据需要调整
    //         modelRef.current.rotation.y = elapsedTime * Math.PI / 6;
    //     });

    //     return <Model modelPath={modelPath} ref={modelRef} />;
    // }

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <Canvas camera={{ position: [15, 3, 15], fov: 50 }}>
                {/* <color attach="background" args={["#ffffff"]} />  */}
                <ambientLight intensity={0.5} />
                <spotLight position={[10, 15, 10]} angle={0.3} />
                <Model modelPath={modelPath} />
                <CameraController />
                <Environment
                    // preset="sunset" 
                    background={false}
                    files="https://shpfile-data-1314977817.cos.ap-guangzhou.myqcloud.com/geojson/venice_sunset_1k.hdr"
                />
            </Canvas>
        </div>
    );
};

export default ModelViewer;