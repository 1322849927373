import { useEffect, useRef, useState } from "react";
import { CustomScroll } from "react-custom-scroll";



const AlarmHistory = () => {
    const [alarmHistoryList] = useState([
        {
            alarmReason: "设备无信号",
            alarmTime: '13:54:55',
            alarmLevel: '1',
            alarmId: '预警序号',
            productionLineName: '5#焊管产线'
        },
        {
            alarmReason: "机械故障",
            alarmTime: '13:54:55',
            alarmLevel: '2',
            alarmId: '预警序号',
            productionLineName: '5#焊管产线'
        },
        {
            alarmReason: "物料缺少",
            alarmTime: '13:54:55',
            alarmLevel: '1',
            alarmId: '预警序号',
            productionLineName: '5#焊管产线'
        },
        {
            alarmReason: "电压过高",
            alarmTime: '13:54:55',
            alarmLevel: '3',
            alarmId: '预警序号',
            productionLineName: '5#焊管产线'
        },
        {
            alarmReason: "传感器失效",
            alarmTime: '13:54:55',
            alarmLevel: '2',
            alarmId: '预警序号',
            productionLineName: '5#焊管产线'
        },
        {
            alarmReason: "断电",
            alarmTime: '13:54:55',
            alarmLevel: '1',
            alarmId: '预警序号',
            productionLineName: '5#焊管产线'
        },
        {
            alarmReason: "过载",
            alarmTime: '13:54:55',
            alarmLevel: '1',
            alarmId: '预警序号',
            productionLineName: '5#焊管产线'
        },
        {
            alarmReason: "继电器故障",
            alarmTime: '13:54:55',
            alarmLevel: '1',
            alarmId: '预警序号',
            productionLineName: '5#焊管产线'
        },
        {
            alarmReason: "设备无信号",
            alarmTime: '13:54:55',
            alarmLevel: '1',
            alarmId: '预警序号',
            productionLineName: '5#焊管产线'
        },
        {
            alarmReason: "设备无信号",
            alarmTime: '13:54:55',
            alarmLevel: '1',
            alarmId: '预警序号',
            productionLineName: '5#焊管产线'
        },
        {
            alarmReason: "设备无信号",
            alarmTime: '13:54:55',
            alarmLevel: '1',
            alarmId: '预警序号',
            productionLineName: '5#焊管产线'
        },
        {
            alarmReason: "设备无信号",
            alarmTime: '13:54:55',
            alarmLevel: '1',
            alarmId: '预警序号',
            productionLineName: '5#焊管产线'
        },
    ])

    const adaptAlarmLevel = (row: any) => {
        switch (row.alarmLevel) {
            case '1':
                return <span style={{ color: '#f5222d' }}>一级告警</span>
                break;
            case '2':
                return <span style={{ color: '#fadb14' }}>二级告警</span>
                break;
            case '3':
                return <span style={{ color: '#ffccc7' }}>三级告警</span>
                break;
            default:
                break;
        }
    }

    const scrollbarsRef = useRef<any>(null);
    const containerRef = useRef<any>(null);

    const [scrollToValue, setScrollToValue] = useState(0);
    const scrollToValueRef = useRef<number>(0);

    useEffect(() => {
        const scrollStep = 1; 
        const scrollInterval = 100; 

        const autoScroll = () => {
            let _scrollToValue = scrollToValueRef.current;
            if (scrollbarsRef.current) {
                const { clientHeight } = containerRef.current; // 直接访问DOM属性
                if (_scrollToValue < clientHeight - 42) {
                    _scrollToValue = _scrollToValue + scrollStep;
                    scrollToValueRef.current = _scrollToValue;
                    setScrollToValue(_scrollToValue);
                } else {
                    scrollToValueRef.current = 0;
                    setScrollToValue(0);
                }
            }
        };
        const intervalId = setInterval(autoScroll, scrollInterval);
        return () => clearInterval(intervalId);
    }, []);


    return (
        <div ref={containerRef} className="alarm-history-panel">
            <CustomScroll
                ref={scrollbarsRef}
                scrollTo={scrollToValue}
                heightRelativeToParent="calc(100% - 42px)"
            >
                <table className="dash-borad-table-el" >
                    <thead>
                        <tr className="dash-borad-table-el-header">
                            <th>告警序号</th>
                            <th className="dash-borad-table-el-first-th">告警内容</th>
                            <th>告警级别</th>
                            <th>告警时间</th>
                            <th>产线名称</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            alarmHistoryList.map((row, index) => {
                                return (
                                    <tr className="dash-borad-table-el-body-row" key={index + ''}>
                                        <td>
                                            <div className="sort-number-text-container">{row.alarmId}</div>
                                        </td>
                                        <td>{row.alarmReason}</td>
                                        <td>{adaptAlarmLevel(row)}</td>
                                        <td >{row.alarmTime}</td>
                                        <td>
                                            {row.productionLineName}
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </CustomScroll>

        </div>
    )
}

export default AlarmHistory;