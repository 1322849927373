import React, { useState, useEffect } from 'react';

function RealtimeClock() {
  // 使用useState钩子初始化时间状态
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    // 设置定时器，每秒更新时间
    const timerId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    // 组件卸载时清除定时器
    return () => {
      clearInterval(timerId);
    };
  }, []); // 空依赖数组确保定时器只在组件挂载时设置一次

  // 格式化时间显示为本地时间字符串，精确到秒
  const timeString = currentTime.toLocaleString('zh-CN', {
    hour12: false // 使用24小时制
  });

  return (
    <div>
      {/* <h2>当前时间</h2> */}
      {/* {new Date().getFullYear() } */}
      {timeString}
    </div>
  );
}

export default RealtimeClock;