import { useState } from "react";


const AlarmReasonTopTen = () => {
    const [reasonTopList] = useState([
        {
            color: '#f5222d',
            reason: "电压过高",
            num: 24,
        },
        {
            color: '#fa541c',
            reason: "传感器失效",
            num: 18,
        },
        {
            color: '#eb2f96',
            reason: "维护不当",
            num: 16,
        },
        {
            color: '#1d39c4',
            reason: "断电",
            num: 14,
        },
        {
            color: '#2f54eb',
            reason: "过载",
            num: 10,
        },
        {
            color: '#597ef7',
            reason: "材料不匹配",
            num: 6,
        },
        {
            color: '#85a5ff',
            reason: "继电器故障",
            num: 4,
        },
        {
            color: '#85a5ff',
            reason: "温度过高",
            num: 3,
        },
        {
            color: '#85a5ff',
            reason: "操作失误",
            num: 3,
        },
        {
            color: '#85a5ff',
            reason: "出料口故障",
            num: 3,
        },
    ])

    return (
        <div className="flex-col reason-top-list">
            {
                reasonTopList.map((item, index) => {
                    return (
                        <div className="flex-row reason-top-item" key={index + ''}>
                            <div 
                                className="flex-col reason-number-box" 
                                style={{
                                    backgroundColor: `${item.color}32`,
                                    color: item.color,
                                    border: `1px solid ${item.color}42`
                             }}
                            >
                                <div className="reason-number-text">{index +1}</div>
                            </div>
                            <div className="reason-top-item-text">{item.reason}</div>
                            <div className="flex-row reason-top-item-line">
                                <div 
                                    className="reason-top-item-line-content"
                                    style={{ 
                                        width: 80 * item.num / reasonTopList[0].num + '%',
                                        backgroundImage: `linear-gradient(to right, ${item.color}88, ${item.color})`
                                    }}
                                ></div>
                            </div>
                            <div className="flex-row reason-top-item-num">{item.num}</div>
                        </div>
                    )
                })
            }

        </div>
    )
}

export default AlarmReasonTopTen;