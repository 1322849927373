import { useEffect } from "react";

interface Props {
    title: string
    value: number
    unit?: string
    color?: string
}

const PercentageBall = (props: Props) => {

    const {
        title,
        value,
        unit = "%",
        color = '#0f7ea4'
    }  = props;

    return (
        <div 
            className="flex-col percentage-ball-container"
        >
            <div 
                className="percentage-ball-wave"
                style={{borderColor: `${color}`, boxShadow: `${color}88 0px 3px 9px` }}
            >
                <div 
                    className="percentage-ball-wave1" 
                    style={{
                        backgroundColor: `${color}52`,
                        top: `${100 - value}%`
                    }}
                ></div>
                <div 
                    className="percentage-ball-wave2" 
                    style={{
                        backgroundColor: `${color}`,
                        top: `${100 - value}%`
                    }}
                ></div>
                <div 
                    className="percentage-ball-wave3" 
                    style={{
                        backgroundColor: `${color}72`,
                        top: `${100 - value}%`
                    }}
                ></div>
            </div>
            <div className="percentage-ball-value">{value}</div>
            <div className="percentage-ball-unit">{unit}</div>
            <div className="percentage-ball-name">{title}</div>
        </div>
    )
}

export default PercentageBall;