import { CSSProperties, PropsWithChildren, forwardRef } from "react"

interface Props {
    title: string
    style?: CSSProperties
    titleLayout?: 'default' | 'absolute'
}

const DashBoradItem = (props: PropsWithChildren & Props) => {

    const {
        children,
        title,
        style,
        titleLayout = 'default'
    } = props;

    const defaultStyle = {};
    return (
        <div className="flex-col dash-borad-item" style={style || defaultStyle}>
            <div
                className="flex-row dash-borad-item-title"
                style={titleLayout == 'absolute' ? { position: 'absolute', left: 0, top: 0, width: 'auto' } : {}}
            >
                <div className="dash-borad-item-title-dot"></div>
                <div className="dash-borad-item-title-text">
                    {title}
                </div>
            </div>
            {children}
        </div>
    )
}

export default forwardRef(DashBoradItem);