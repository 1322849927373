import { FactoryBuilding } from "@icon-park/react";
import "./index.scss";
import DashBoradItem from "./components/DashBoradItem";
import EquipmentAlarm from "./components/EquipmentAlarm";
import EquipmentCart from "./components/EquipmentCart";
import EquipmentDailyStatus from "./components/EquipmentDailyStatus";
import PercentageBall from "./components/PercentageBall";
import AlarmReasonTopTen from "./components/AlarmReasonTopTen";
import AlarmHistory from "./components/AlarmHistory";
import EquipmentShotDownChart from "./components/EquipmentShotDownChart";
import DataCollectRecord from "./components/DataCollectRecord";
import RealtimeClock from "./components/RealtimeClock";
import { useEffect } from "react";
import DashBoardApi from "../../api/api";

const DashBorad = () => {

    useEffect(() => {
        DashBoardApi.getEquipmentAlarmRecords({})
        .then(res => {
            console.log("设备数据1--->", res)
        })
        .catch(err => {
            console.log("错误--->", err)
        })
    },[]);

    return (
        <div className="flex-col dash-borad-container">
            <div className="flex-row dash-borad-nav">
                <div className="flex-row dash-borad-nav-left">
                    <div className="flex-col dash-borad-nav-logo">
                        {/* <FactoryBuilding theme="outline" size="24" fill="#16aa85" /> */}
                        <img 
                            src={require('./../../assets/images/logo.png')}
                            className="dash-borad-nav-logo-icon"
                        />
                    </div>
                    <div className="flex-col dash-borad-nav-title-line">
                        <div className="dash-borad-nav-title">嘉兴永励</div>
                        <div className="dash-borad-nav-title-small">
                            <span>JIAXING</span>&nbsp;&nbsp;<span>YONGLI</span>
                        </div>
                    </div>
                    <div className="dash-borad-nav-title-border-line"></div>
                    {/* <div style={{ fontFamily: 'BiaoTi' }} className="dash-borad-sys-title">5#焊管线线-高频焊接</div> */}
                    <div style={{ fontFamily: 'BiaoTi' }} className="dash-borad-sys-title">5#焊管线-高频焊接</div>
                </div>
                <div className="flex-row" style={{fontFamily: 'BiaoTi', fontSize: 24}}>
                    RealTime&nbsp;:&nbsp;
                    <RealtimeClock/>
                </div>
            </div>
            <div className="flex-row dash-borad-content">
                <div
                    className="flex-col"
                    style={{ width: '50%', height: '100%', paddingLeft: 12, paddingBottom: 12 }}
                >
                    <DashBoradItem
                        title="设备今日运行状态"
                        style={{ width: "100%", height: 160 }}
                    >
                        <EquipmentDailyStatus />
                    </DashBoradItem>
                    <DashBoradItem
                        title="设备实时运行状态"
                        style={{ width: "100%", height: '42vh' }}
                    >
                        <EquipmentAlarm />
                    </DashBoradItem>
                    <DashBoradItem
                        title="监控看板"
                        style={{ width: "100%", flex: 1 }}
                        titleLayout={'absolute'}
                    >
                        <EquipmentCart />
                    </DashBoradItem>
                </div>
                <div 
                    className="flex-col"
                    style={{ width: '50%', height: '100%', padding: 12 }}
                >
                    <div 
                        className="flex-row" 
                        style={{ width: '100%', height: 'calc(42vh + 160px + 12px)' }}
                    >
                        <div
                            className="flex-col"
                            style={{ width: '40%', height: '100%', alignItems: 'flex-start' }}
                        >
                            <div className="flex-row" style={{ width: '100%', height: '160px', flexShrink: 0 }}>
                                <PercentageBall 
                                    title="设备OEE"
                                    value={79.1}
                                    color={'#6e92f7'}
                                />
                                <PercentageBall 
                                    title="设备良品率"
                                    value={92.7}
                                    color="#e88d32"
                                />
                                <PercentageBall 
                                    title="设备坏品率"
                                    value={8.3}
                                    color="#aa7bf7"
                                />
                            </div>
                            <DashBoradItem
                                title="预警记录Top10"
                                style={{ width: 'calc(100% - 12px)', flex: 1 }}
                            >
                                <AlarmReasonTopTen />
                            </DashBoradItem>
                        </div>
                        <div
                            className="flex-col"
                            style={{ width: '60%', height: '60vh' }}
                        >
                            <DashBoradItem
                                title="预警看板"
                                style={{ width: '100%', height: '35vh', marginTop: 0 }}
                            >
                                <AlarmHistory />
                            </DashBoradItem>
                            <DashBoradItem
                                title="预警记录"
                                style={{ width: '100%', flex: 1 }}
                            >
                                <EquipmentShotDownChart />
                            </DashBoradItem>
                        </div>
                    </div>
                    <DashBoradItem
                        title="数采记录"
                        style={{ width: '100%',flex: 1}}
                    >
                        <DataCollectRecord/>
                    </DashBoradItem>
                </div>
            </div>
        </div>
    );
};

export default DashBorad;
