import axios from 'axios'
axios.defaults.timeout = 20 * 1000;
axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.defaults.baseURL = '';

axios.interceptors.request.use((config) => {
    config.headers.Accept = "application/json";
    config.headers['Content-Type'] = "application/json";
    // if (localStorage.getItem('token')) {
    //     config.headers['authorization'] = `Bearer ${localStorage.getItem('token')}`;
    // }
    // if (config.method === 'post') {
    //     delete config.data._id;
    //     config.data = JSON.stringify(config.data);
    // }
    return config;
}, (error) => {
    console.log('错误的传参')
    return Promise.reject(error);
});

axios.interceptors.response.use((res) => {
    if (res.data.code == 200 || res.data.errcode == 200) {
        return Promise.resolve(res);
    } else if (res.data.code == 401) {
    } else {
    }
    return res;
}, (error) => {
    if (error.response.data.code == 401) {
       
    } else {
       
    }
    return Promise.reject(error);
});

declare namespace Service {
    interface ListResult {
        code: number
        data?: {
            list?: Array<any>
            id?: string
        }
        msg: string
    }
}

const postRequest = (url: string, param: any): Promise<Service.ListResult> => {
    let requestUrl = url + "";
    return new Promise((resolve, reject) => {
        axios.post(requestUrl, param)
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err);
            })
            .catch((error) => {
                reject(error)
            })
    })
};


const getRequest = (url: string, param: any) => {
    return new Promise((resolve, reject) => {
        axios.get(url, { params: param })
            .then(response => {
                resolve(response)
            }, err => {
                reject(err)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export {
    postRequest,
    getRequest
}
