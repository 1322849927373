import { forwardRef, useEffect, useRef } from "react"
import { Canvas, useThree, useLoader, extend } from '@react-three/fiber';
// import modelPath from './../../../assets/3d-model/test-4/3d66.com_695886.glb'
import ModelViewer from "./GLBModel";
import { AlarmClock, Avatar, Calendar, IMac, Info, ListNumbers, ProjectorTwo } from "@icon-park/react";


const EquipmentAlarm = () => {


    return (
        <div
            style={{ width: "100%", height: '100%', paddingLeft: 12, alignItems: 'flex-start' }}
            className="flex-row"
        >
            <div className=" blinking-alert" style={{ width: `calc(100% - 280px)`, height: 'calc(100% - 12px)', marginBottom: 12, borderRadius: 12 }}>
                {/* <ModelViewer modelPath={modelPath} /> */}
                <ModelViewer modelPath={'https://other-1314977817.cos.ap-guangzhou.myqcloud.com/3d66.com_695886.0d41e669b323e81cdd3e.glb'} />
            </div>
            <div className="flex-col equipment-info-list">
                <div className="flex-row equipment-info-row">
                    <div className="equipment-info-row-left">
                        <div className="equipment-info-row-icon">
                            <ProjectorTwo theme="multi-color" size="20" fill={['#ffffff', '#2F88FF', '#FFF', '#43CCF8']} />
                        </div>
                        <div className="equipment-info-row-name">设备名称</div>
                    </div>
                    <div className="equipment-info-row-value">5#焊管机</div>
                </div>
                <div className="flex-row equipment-info-row">
                    <div className="equipment-info-row-left">
                        <div className="equipment-info-row-icon">
                            <ListNumbers theme="multi-color" size="20" fill={'#2F88FF'} />
                        </div>
                        <div className="equipment-info-row-name">设备编号</div>
                    </div>
                    <div className="equipment-info-row-value">03963437</div>
                </div>
                <div className="flex-row equipment-info-row">
                    <div className="equipment-info-row-left">
                        <div className="equipment-info-row-icon">
                            <Calendar theme="multi-color" size="20" fill={['#ffffff', '#2F88FF', '#FFF', '#43CCF8']} />
                        </div>
                        <div className="equipment-info-row-name">生广日期</div>
                    </div>
                    <div className="equipment-info-row-value">2014年10月9日</div>
                </div>
                <div className="flex-row equipment-info-row">
                    <div className="equipment-info-row-left">
                        <div className="equipment-info-row-icon">
                            <Avatar theme="multi-color" size="20" fill="['#ffffff' ,'#2F88FF' ,'#FFF' ,'#43CCF8']" />
                        </div>
                        <div className="equipment-info-row-name">设备负责人</div>
                    </div>
                    <div className="equipment-info-row-value">李**</div>
                </div>
                <div className="flex-row equipment-info-row">
                    <div className="equipment-info-row-left">
                        <div className="equipment-info-row-icon">
                            <Info theme="multi-color" size="20" fill={['#ffffff', '#2F88FF', '#FFF', '#43CCF8']} />
                        </div>
                        <div className="equipment-info-row-name">运行状态</div>
                    </div>
                    <div className="equipment-info-row-value" style={{color: '#cf1322'}}>告警</div>
                </div>
                <div className="flex-row equipment-info-row">
                    <div className="equipment-info-row-left">
                        <div className="equipment-info-row-icon">
                            <AlarmClock theme="multi-color" size="20" fill={['#ffffff', '#2F88FF', '#FFF', '#43CCF8']} />
                        </div>
                        <div className="equipment-info-row-name">运行时长</div>
                    </div>
                    <div className="equipment-info-row-value">12小时21分钟</div>
                </div>
                <div className="flex-row equipment-info-row">
                    <div className="equipment-info-row-left">
                        <div className="equipment-info-row-icon">
                            <AlarmClock theme="multi-color" size="20" fill={['#ffffff', '#f5222d', '#FFF', '#f5222d']} />
                        </div>
                        <div className="equipment-info-row-name">告警时长</div>
                    </div>
                    <div className="equipment-info-row-value">3分钟</div>
                </div>
            </div>
        </div>
    )
}

export default forwardRef(EquipmentAlarm);