import { useState } from "react";



const EquipmentDailyStatus = () => {
    //运行 #5d8ef4
    //待料 #9df8e2
    //停机 #e99f40
    //告警 #da4b47
    //调试 #b187f7

    const [legendList] = useState([
        {
            name: "运行",
            color: "#5d8ef4"
        },
        {
            name: "待料",
            color: "#9df8e2"
        },
        {
            name: "停机",
            color: "#e99f40"
        },
        {
            name: "告警",
            color: "#da4b47"
        },
        {
            name: "调试",
            color: "#b187f7"
        },
    ]);

    const [statusList, setStatusList] = useState([
        {
            color: '#5d8ef4',
            percent: 10,
        },
        {
            color: '#9df8e2',
            percent: 5,
        },
        {
            color: '#5d8ef4',
            percent: 30,
        },
        {
            color: '#e99f40',
            percent: 25,
        },
        {
            color: '#da4b47',
            percent: 30,
        },
        {
            color: '#b187f7',
            percent: 10,
        },
    ])

    const [timeLineList] = useState([
        '8:00',
        '9:00',
        '10:00',
        '11:00',
        '12:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
        '18:00',
    ])
    

    return (
        <div className="flex-col equipment-daily-status">
            <div className="flex-row equipment-daily-status-legend">
                {
                    legendList.map((legend, index) => {
                        return (
                            <div className="flex-row legend-item">
                                <div className="legend-item-box" style={{backgroundColor: legend.color}}></div>
                                <div className="legend-item-name">{legend.name}</div>
                            </div>
                        )
                    })
                }
            </div>
            <div className="flex-row equipment-daily-status-content">
                {
                    statusList.map((status,index) => {
                        return (
                            <div style={{backgroundColor: status.color, width: `${status.percent}%`}} className="status-row"></div>
                        )
                    })
                }
            </div>
            <div className="flx-row equipment-daily-status-timeline">
                {
                    timeLineList.map((point, index) => {
                        return (
                            <div className="flex-row timeline-point" key={index + ''}>
                                <div>{point}</div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}


export default EquipmentDailyStatus;