
import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';

const EquipmentCart = () => {
    const chartRef = useRef<any>(null);
    let chartInstance = useRef<any>(null);;

    // 初始化图表
    // const initChart = () => {
    //     const renderChart = () => {
    //         const chart = echarts.init(chartRef.current);
    //         const option = {
    //             xAxis: {
    //                 type: 'category',
    //                 boundaryGap: false,
    //                 data: [],
    //                 axisLabel: {
    //                     textStyle: {
    //                         color: 'rgba(255,255,255,0.72)',
    //                     }
    //                 }
    //             },
    //             yAxis: {
    //                 type: 'value',
    //                 boundaryGap: [0, 100],
    //                 min: 0,
    //                 max: 100,
    //                 splitLine: {
    //                     lineStyle: {
    //                         color: 'rgba(255,255,255,0.42)',
    //                         type: 'dashed' // 设置横向网格线为虚线
    //                     }
    //                 },
    //                 axisLabel: {
    //                     textStyle: {
    //                         color: 'rgba(255,255,255,0.72)',
    //                     }
    //                 },
    //                 name: '%', // 在 Y 轴顶部添加 '%' 表示单位
    //                 nameLocation: 'end', // 将 '%' 放置在顶部
    //                 nameTextStyle: { // 调整 '%' 的样式
    //                     verticalAlign: 'bottom',
    //                     color: 'rgba(255,255,255,0.72)',
    //                     padding: [0, 0, 3, 0] // 调整位置，确保 '%' 不会与图表边界重叠
    //                 }
    //             }, 
    //             series: [
    //                 {
    //                 name: "电压百分比",
    //                 data: [],
    //                 type: 'line',
    //                 smooth: true, 
    //                 showSymbol: false,
    //                 areaStyle: { 
    //                     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    //                         { offset: 0, color: '#f5222d72' }, // 渐变起始颜色
    //                         { offset: 1, color: '#52c41a82' } // 渐变结束颜色
    //                     ])
    //                 },
    //             },

    //         ],
    //             grid: {
    //                 top: '10%',
    //                 left: '5%',
    //                 right: '3%',
    //                 bottom: '12%',
    //             },
    //             visualMap: {
    //                 show: false,
    //                 type: "continuous",
    //                 min: 0,
    //                 max: 30, // 假设30是数据的最大值
    //                 dimension: 1, // 根据Y轴的值来变化颜色
    //                 inRange: {
    //                     // 定义从绿色到红色的渐变色
    //                     color: ["#52c41a", "#f5222d"], // 使用十六进制颜色代码
    //                 },
    //             },
    //             animationEasing: 'linear',
    //             animationDuration: 1800,
    //         };
    //         //@ts-ignore
    //         chart.setOption(option);
    //         return chart;
    //     };

    //     if (!chartInstance.current) {
    //         //@ts-ignore
    //         chartInstance.current = renderChart();
    //     }

    //     // 生成初始数据
    //     const now = new Date();
    //     const oneMinute = 60000;
    //     let data: any[] = [];
    //     for (let i = 59; i >= 0; i--) {
    //         //@ts-expect-error
    //         const time = new Date(now - i * oneMinute);
    //         data.push([echarts.format.formatTime('hh:mm', time), Math.random() * 30 + 70]); // 变动区间在 70～100 之间
    //     }

    //     // 更新图表
    //     chartInstance.current.setOption({
    //         xAxis: {
    //             data: data.map(item => item[0])
    //         },
    //         series: [{
    //             data: data.map(item => item[1])
    //         }]
    //     });

    //     // 定时更新数据
    //     const updateData = () => {
    //         data.shift();
    //         const now = new Date();
    //         data.push([echarts.format.formatTime('hh:mm', now), Math.random() * 30 + 70]); // 新的电压值在 70～100 之间

    //         chartInstance.current.setOption({
    //             xAxis: {
    //                 data: data.map(item => item[0])
    //             },
    //             series: [{
    //                 data: data.map(item => item[1])
    //             }]
    //         });
    //     };


    //     const intervalId = setInterval(updateData, 1000);

    //     return () => clearInterval(intervalId);
    // };

    const initChart = () => {
        const renderChart = () => {
            const chart = echarts.init(chartRef.current);
            const option = {
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: [],
                    axisLabel: {
                        textStyle: {
                            color: 'rgba(255,255,255,0.72)',
                        }
                    }
                },
                yAxis: {
                    type: 'value',
                    boundaryGap: [0, 100],
                    min: 0,
                    max: 100,
                    splitLine: {
                        lineStyle: {
                            color: 'rgba(255,255,255,0.42)',
                            type: 'dashed' // 设置横向网格线为虚线
                        }
                    },
                    axisLabel: {
                        textStyle: {
                            color: 'rgba(255,255,255,0.72)',
                        }
                    },
                    name: '%',
                    nameLocation: 'end',
                    nameTextStyle: {
                        verticalAlign: 'bottom',
                        color: 'rgba(255,255,255,0.72)',
                        padding: [0, 0, 3, 0]
                    }
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    },
                    // 使用 formatter 函数来自定义 tooltip 的内容
                    //@ts-ignore
                    formatter: function (params) {
                        let result = params[0].axisValue + '<br/>'; // 显示轴的值
                        //@ts-ignore
                        params.forEach(function (item) {
                            result += item.marker + " " + item.seriesName + ": " + item.value + '%' + '<br/>'; // 添加百分比符号
                        });
                        return result;
                    },
                },
                legend: {
                    data: ['电压百分比', '电流百分比', '高频报警百分比', '停机信号百分比'],
                    textStyle: {
                        color: 'rgba(255,255,255,0.72)', // 图例文字颜色
                    },
                    icon: 'rect',
                    top: '4%',
                    right: '3%' // 图例位置
                },
                series: [
                    {
                        name: "电压百分比",
                        data: [],
                        type: 'line',
                        smooth: true,
                        showSymbol: false,
                        areaStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: '#f5222d72' }, // 渐变起始颜色
                                { offset: 1, color: '#52c41a82' } // 渐变结束颜色
                            ])
                        },
                    },
                    // 为其他指标复制上面的配置，修改 name 和 areaStyle.color 以区分
                    // 以下是简化的配置，实际应用中你需要根据每条线的特性进行调整
                    {
                        name: "电流百分比",
                        data: [],
                        type: 'line',
                        smooth: true,
                        showSymbol: false,
                        areaStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: '#f5222d72' }, // 渐变起始颜色
                                { offset: 1, color: '#52c41a82' } // 渐变结束颜色
                            ])
                        },
                        // 设置不同的颜色或样式
                    },
                    {
                        name: "高频报警百分比",
                        data: [],
                        type: 'line',
                        smooth: true,
                        showSymbol: false,
                        areaStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: '#f5222d72' }, // 渐变起始颜色
                                { offset: 1, color: '#52c41a82' } // 渐变结束颜色
                            ])
                        },
                        // 设置不同的颜色或样式
                    },
                    {
                        name: "停机信号百分比",
                        data: [],
                        type: 'line',
                        smooth: true,
                        showSymbol: false,
                        areaStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: '#f5222d72' }, // 渐变起始颜色
                                { offset: 1, color: '#52c41a82' } // 渐变结束颜色
                            ])
                        },
                        // 设置不同的颜色或样式
                    }
                ],
                grid: {
                    top: '20%',
                    left: '5%',
                    right: '3%',
                    bottom: '12%',
                },
                // animationEasing: 'linear',
                // animationDuration: 1800,
            };
            //@ts-ignore
            chart.setOption(option);
            return chart;
        };

        if (!chartInstance.current) {
            chartInstance.current = renderChart();
        }

        // 初始化数据
        let initData = () => {
            const now: any = new Date();
            const oneMinute = 60000;
            let baseData = [];
            for (let i = 59; i >= 0; i--) {
                const time = new Date(now - i * oneMinute);
                // baseData.push([echarts.format.formatTime('hh:mm', time), Math.random() * 90 + 90]);
                baseData.push([echarts.format.formatTime('hh:mm', time), Number((Math.random() * 100).toFixed(2))]);
            }
            return baseData;
        };

        let data: {
            voltage: any[]
            current: any[]
            alarm: any[]
            shutdown: any[]
        } = {
            voltage: initData(), // 电压数据
            current: initData(), // 电流数据
            alarm: initData(), // 报警数据
            shutdown: initData() // 停机数据
        };

        // 更新图表
        const updateChart = () => {
            chartInstance.current.setOption({
                xAxis: {
                    data: data.voltage.map(item => item[0]) // 假设所有数据系列的时间轴相同
                },
                series: [
                    { name: "电压百分比", data: data.voltage.map(item => item[1]) },
                    { name: "电流百分比", data: data.current.map(item => item[1]) },
                    { name: "高频报警百分比", data: data.alarm.map(item => item[1]) },
                    { name: "停机信号百分比", data: data.shutdown.map(item => item[1]) }
                ]
            });
        };

        updateChart();

        // 定时更新数据
        const updateData = () => {
            // 更新每个系列的数据
            // 这里只是简单演示，实际应用中可能需要根据不同的逻辑更新数据
            Object.keys(data).forEach(key => {
                //@ts-ignore
                data[key].shift();
                const now = new Date();
                //@ts-ignore
                // data[key].push([echarts.format.formatTime('hh:mm', now), Math.random() * 30 + 70]);
                data[key].push([echarts.format.formatTime('hh:mm', now), Number((Math.random() * 100).toFixed(2))]);
            });

            updateChart();
        };

        const intervalId = setInterval(updateData, 1000);

        return () => clearInterval(intervalId);
    };

    useEffect(() => {
        initChart();

        return () => {
            // 组件卸载时销毁图表实例
            if (chartInstance.current) {
                chartInstance.current.dispose();
            }
        };
    }, []);

    return <div ref={chartRef} style={{ width: '100%', height: '100%', overflow: 'hidden', boxSizing: 'border-box' }} />;
};

export default EquipmentCart;
