import { getRequest } from "./request"


const DashBoardApi = {
    getEquipmentRealTimeData: (params: any) => {
        return getRequest('/api/DataAcquisition/GetData', params);
    },
    
    getEquipmentAlarmRecords: (params: any) => {
        return getRequest('/api/DataAcquisition/GetAlarmRecords', params)
    },

    getEquipmentCurrentLnv: (params: any) => {
        return getRequest('/api/DataAcquisition/GetCurrentInv', params)
    }
}

export default DashBoardApi;