import { useEffect, useRef, useState } from "react";
import { CustomScroll } from "react-custom-scroll";



const DataCollectRecord = () => {
    const [recordList] = useState([
        {
            id: '1'
        },
        {
            id: '1'
        },
        {
            id: '1'
        },
        {
            id: '1'
        },
        {
            id: '1'
        },
        {
            id: '1'
        },
        {
            id: '1'
        },
        {
            id: '1'
        },
        {
            id: '1'
        },
        {
            id: '1'
        },
        {
            id: '1'
        },
        {
            id: '1'
        },
        {
            id: '1'
        },
    ])

    const scrollbarsRef = useRef<any>(null);
    const containerRef = useRef<any>(null);

    const [scrollToValue, setScrollToValue] = useState(0);
    const scrollToValueRef = useRef<number>(0);

    useEffect(() => {
        const scrollStep = 1; 
        const scrollInterval = 100; 

        const autoScroll = () => {
            let _scrollToValue = scrollToValueRef.current;
            if (scrollbarsRef.current) {
                const { clientHeight } = containerRef.current; // 直接访问DOM属性
                if (_scrollToValue < clientHeight - 42) {
                    _scrollToValue = _scrollToValue + scrollStep;
                    scrollToValueRef.current = _scrollToValue;
                    setScrollToValue(_scrollToValue);
                } else {
                    scrollToValueRef.current = 0;
                    setScrollToValue(0);
                }
            }
        };
        const intervalId = setInterval(autoScroll, scrollInterval);
        return () => clearInterval(intervalId);
    }, []);


    return (
        <div ref={containerRef} className="alarm-history-panel">
            <CustomScroll
                ref={scrollbarsRef}
                scrollTo={scrollToValue}
                heightRelativeToParent="calc(100% - 42px)"
            >
                <table className="dash-borad-table-el" >
                    <thead>
                        <tr className="dash-borad-table-el-header">
                            <th>产线名称</th>
                            <th>产品规格</th>
                            <th>批次</th>
                            <th>计划数</th>
                            <th>汇报数</th>
                            <th>焊接功率</th>
                            <th>焊接频率</th>
                            <th>实际电流</th>
                            <th>实际电压</th>
                            <th>设备运行状态</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            recordList.map((row, index) => {
                                return (
                                    <tr className="dash-borad-table-el-body-row" key={index + ''}>
                                        <td>{'*****'}</td>
                                        <td>{'*****'}</td>
                                        <td >{'*****'}</td>
                                        <td>{'*****'}</td>
                                        <td>{'*****'}</td>
                                        <td>{'*****'}</td>
                                        <td>{'*****'}</td>
                                        <td>{'*****'}</td>
                                        <td>{'*****'}</td>
                                        <td>{'*****'}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </CustomScroll>

        </div>
    )

}

export default DataCollectRecord;